export const systemReports= [
    {
        name: "Sales Report",
        date: "10-Jan to 10-Feb"
    },
    {
        name: "Revenue",
        date: "10-Jan to 10-Feb"
    },
    {
        name: "Stock",
        date: "10-Jan to 10-Feb"
    },
    {
        name: "Member Detail",
        date: "10-Jan to 10-Feb"
    },
    {
        name: "Balance Sheet",
        date: "10-Jan to 10-Feb"
    },
    {
        name: "Sales Report",
        date: "10-Jan to 10-Feb"
    }
]