export const browserStats= [
	{
		browserName: "Google Chrome",
		runningPercentage: "25%",
		completedSteps: 25,
		imgUrl:"/static/img/browser-logo/chrome.png"
	},
	{
		browserName: "FireFox",
		runningPercentage: "56%",
		completedSteps: 45,
		imgUrl:"/static/img/browser-logo/firefox.png"
	},
	{
		browserName: "Safari",
		runningPercentage: "35%",
		completedSteps: 35,
		imgUrl:"/static/img/browser-logo/safari.png"
	},
	{
		browserName: "Internet Explorer",
		runningPercentage: "16%",
		completedSteps: 16,
		imgUrl:"/static/img/browser-logo/internet-explorer.png"
	},
	{
		browserName: "Opera Mini",
		runningPercentage: "70%",
		completedSteps: 70,
		imgUrl:"/static/img/browser-logo/opera.png"
	},
	{
		browserName: "UC Browser",
		runningPercentage: "45%",
		completedSteps: 45,
		imgUrl:"/static/img/browser-logo/uc.png"
	},
	{
		browserName: "Ubuntu Web Browser",
		runningPercentage: "22%",
		completedSteps: 22,
		imgUrl:"/static/img/browser-logo/ubuntu.png"
	}
]