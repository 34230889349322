export const citiesAndClients= [
    {
        name: "East Orin(2500)",
        percentage: "17.98%",
        value: 50,
        color: "primary gradient-primary"
    },
    {
        name: "Hesselton(3500)",
        percentage: "52.00%",
        value: 75,
        color: "warning gradient-warning"
    },
    {
        name: "Lake Aniyah(2800)",
        percentage: "17.98%",
        value: 17.9,
        color: "warning gradient-warning"
    },
    {
        name: "Flaviehaven(3000)",
        percentage: "25.69%",
        value: 23.73,
        color: "info gradient-info"
    },
    {
        name: "Sterlingberg(5000)",
        percentage: "12.58%",
        value: 47.63,
        color: "primary gradient-pink"
    },
    {
        name: "Moorechester(1500)",
        percentage: "20.25%",
        value: 50.6,
        color: "danger gradient-danger"
    }
]